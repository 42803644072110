import React from 'react'

// GraphQL
import { getGraphCharacters } from '../../../graphQL'

// Components
import CharacterCard from '../../molecules/CharacterCard'

// Styles
import { biosWrapper } from './styles.module.scss'

const BiosPanel: React.FC = () => {
  const characters = getGraphCharacters().sort((a, b) => {
    if (a.name > b.name) return 1
    return -1
  })

  return (
    <>
      <div className={biosWrapper}>
        {characters.map((character) => (
          <CharacterCard
            key={`Bios_list_${character.name}`}
            town={character.town?.name}
            name={character.name}
          />
        ))}
      </div>
    </>
  )
}

export default BiosPanel
