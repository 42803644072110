import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

// Components
import BiosPanel from '../../../components/organisms/BiosPanel'
import PageTemplate from '../../../components/templates/PageTemplate'
import WorldTemplate from '../../../components/templates/WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

const BiosPage = () => {
  const { language } = useContext(LocalizationContext)
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Biografias ' : 'Bios '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Lee la historia de nuestros personajes, su pasado y qué los motiva.'
              : 'Read the story of our characters, their past and what motivates them.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, mundo, biografias'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, world, bios'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate location="bios">
        <BiosPanel />
      </WorldTemplate>
    </PageTemplate>
  )
}

export default BiosPage
