import React from 'react'
import { navigate } from 'gatsby'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// Styles
import {
  characterPb,
  characterImg,
  characterName,
  characterCard,
  characterEmblem,
  characterBottom,
} from './styles.module.scss'

interface Props {
  town: string
  name: string
}

const CharacterCard: React.FC<Props> = ({ town, name }) => {
  return (
    <div
      className={characterCard}
      onClick={() => {
        navigate(`/world/bios/${createPath(name)}`)
      }}
    >
      <img
        className={characterImg}
        src={`https://dilancovak.com/.img/bios/${createPath(name)}.png`}
      />
      <div className={characterBottom}>
        <img
          className={characterEmblem}
          src={`https://dilancovak.com/.img/emblems/${createPath(town)}.png`}
        />
        <div
          className={`${characterName} ${getFont('font-lato', characterPb)}`}
        >
          {name}
        </div>
      </div>
    </div>
  )
}

export default CharacterCard
